import {
  assert,
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  changePropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  ISlideShowContainerOwnSDKFactory,
  SlideShowContainerProps,
  ISlideShowContainerSDK,
} from '../SlideShowContainer.types';
import { ISlideShowSlideSDK } from '../../SlideShowSlide/SlideShowSlide.types';
import { isValidSlideReference } from './customValidations';

const _slideShowContainerSDKFactory: ISlideShowContainerOwnSDKFactory = ({
  sdkData,
  setStyles,
  props,
  compRef,
  getChildren,
  metaData,
}) => {
  const _state = {
    showSlideButtons: sdkData.showSlideButtons,
    showNavigationButtons: sdkData.showNavigationButtons,
  };

  const sdkProps = {
    changeSlide: async (slideReference: number | ISlideShowSlideSDK) => {
      const slides = getChildren();
      const nextSlideIndex = assert.isNumber(slideReference)
        ? slideReference
        : slides.findIndex(slide => slide.id === slideReference.id);

      await compRef.moveToSlide(nextSlideIndex);

      return slides[nextSlideIndex];
    },
    get slides() {
      return getChildren();
    },
    get currentSlide() {
      const slides = getChildren();
      return slides[props.currentSlideIndex];
    },
    get currentIndex() {
      return props.currentSlideIndex;
    },
    get showSlideButtons() {
      return _state.showSlideButtons;
    },
    set showSlideButtons(show: boolean) {
      setStyles({
        '--nav-dot-section-display': show
          ? 'block !important'
          : 'none !important',
      });
      _state.showSlideButtons = show;
    },
    get showNavigationButtons() {
      return _state.showNavigationButtons;
    },
    set showNavigationButtons(show: boolean) {
      setStyles({
        '--nav-button-display': show ? 'block !important' : 'none !important',
      });
      _state.showNavigationButtons = show;
    },
    get type() {
      return '$w.Slideshow';
    },
    toJSON() {
      const { currentIndex, type } = sdkProps;
      return {
        ...toJSONBase(metaData),
        type,
        currentIndex,
        showNavigationButtons: _state.showNavigationButtons,
        showSlideButtons: _state.showSlideButtons,
      };
    },
  };

  return sdkProps;
};

const customRules = {
  changeSlide: [isValidSlideReference],
};
const slideShowContainerSDKFactory = withValidation(
  _slideShowContainerSDKFactory,
  {
    type: ['object'],
    properties: {
      changeSlide: {
        type: ['function'],
        args: [
          {
            type: ['object', 'integer'],
          },
        ],
      },
      showSlideButtons: {
        type: ['boolean'],
      },
      showNavigationButtons: {
        type: ['boolean'],
      },
    },
  },
  customRules,
);

export const sdk = composeSDKFactories<
  SlideShowContainerProps,
  ISlideShowContainerSDK
>(
  elementPropsSDKFactory,
  changePropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
  slideShowContainerSDKFactory,
);
