import {
  withValidation,
  composeSDKFactories,
  assert,
} from '@wix/editor-elements-corvid-utils';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  clickPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  IGridOwnSDKFactory,
  IGridProps,
  IGridSDK,
  GridLoadRowsEvent,
} from '../Grid.types';
import {
  DataSource,
  PaginationType,
  DEFAULT_ROWS_PER_PAGE,
} from '../constants';

const ownSDKFactory: IGridOwnSDKFactory = ({
  setProps,
  registerEvent,
  props,
  metaData,
}) => {
  const type = '$w.Table';
  let dataFetcher: IGridSDK['dataFetcher'] = null;
  let lastDataFetcherRequestId = 0;

  const processNewRows = (rows: IGridSDK['rows']) => {
    // TODO: Validate dates, process links and images
    return rows.map(row => ({ ...row }));
  };

  const loadRows = async (
    startRow: number,
    endRow: number,
    isFirstLoad: boolean,
  ) => {
    if (!dataFetcher) {
      return;
    }

    const paginationType = props.pagination.type;
    const pagination = paginationType === PaginationType.None && {
      ...props.pagination,
      type: PaginationType.Scroll,
    };

    setProps({
      isLoading: true,
      dataSource: DataSource.Dynamic,
      ...(pagination && { pagination }),
      ...(isFirstLoad && {
        rows: [],
        currentPage: 1,
      }),
    });

    const requestId = ++lastDataFetcherRequestId;
    const { pageRows, totalRowsCount } = await dataFetcher(startRow, endRow);

    // UI can trigger new requests to load rows while there are still pending
    // requests in progress. To avoid updating UI with out of order data we
    // ignore stale dataFetcher results.
    if (requestId !== lastDataFetcherRequestId) {
      return;
    }

    const newRows = processNewRows(pageRows);
    const shouldAppend =
      !isFirstLoad && paginationType !== PaginationType.Pages;
    const rows = shouldAppend ? props.rows.concat(newRows) : newRows;

    setProps({
      isLoading: false,
      lastLoadedRowsCount: newRows.length,
      rows,
      totalRowsCount,
    });
  };

  registerEvent<GridLoadRowsEvent>('onLoadRows', ({ startRow, endRow }) =>
    loadRows(startRow, endRow, false),
  );

  return {
    set pagination(value) {
      setProps({ pagination: { ...value } });
    },
    get pagination() {
      return { ...props.pagination };
    },
    set columns(value) {
      const columns = assert.isArray(value)
        ? value.map(column => ({ ...{ visible: true }, ...column }))
        : [];

      setProps({ columns });
    },
    get columns() {
      return props.columns.map(column => ({ ...column }));
    },
    set rows(value) {
      const rows = assert.isArray(value) ? value : [];

      // Ignore any pending requests from previous dynamic data source
      lastDataFetcherRequestId = 0;

      setProps({
        isLoading: false,
        dataSource: DataSource.Static,
        rows: processNewRows(rows),
      });
    },
    get rows() {
      return props.rows.map(row => ({ ...row }));
    },
    set dataFetcher(value) {
      dataFetcher = value;
      const endRow = props.pagination.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
      loadRows(0, endRow, true);
    },
    get dataFetcher() {
      return dataFetcher;
    },
    get type() {
      return type;
    },
    toJSON() {
      const { pagination, columns, rows } = props;
      return {
        ...toJSONBase(metaData),
        type,
        pagination,
        columns,
        rows,
      };
    },
  };
};

const ownSDKFactoryWithValidation = withValidation(ownSDKFactory, {
  type: ['object'],
  properties: {
    pagination: {
      type: ['object'],
      properties: {
        type: {
          type: ['string'],
          enum: Object.values(PaginationType),
        },
        rowsPerPage: {
          type: ['number'],
        },
      },
      required: ['type'],
    },
    columns: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
        properties: {
          id: { type: ['string'] },
        },
        required: ['id'],
      },
    },
    rows: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
      },
    },
    dataFetcher: {
      type: ['function', 'nil'],
    },
  },
});

export const sdk = composeSDKFactories<IGridProps, IGridSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  ownSDKFactoryWithValidation,
);
