import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { EventHandler } from 'react';
import { withValidation } from '@wix/editor-elements-corvid-utils';

export interface IViewportPropSDK {
  onViewportEnter(cb: EventHandler<any>): void;
  onViewportLeave(cb: EventHandler<any>): void;
}

const _viewportPropsSDKFactory: CorvidSDKPropsFactory<{}, IViewportPropSDK> = ({
  handlers,
  metaData,
}) => {
  return {
    onViewportEnter: (cb: EventHandler<any>) =>
      handlers.onViewportEnter(metaData.compId, cb),
    onViewportLeave: (cb: EventHandler<any>) =>
      handlers.onViewportLeave(metaData.compId, cb),
  };
};

export const viewportPropsSDKFactory = withValidation(
  _viewportPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      onViewportEnter: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
      onViewportLeave: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
    },
  },
);
